<template>
    <aside  :class="`abc ${($store.getters['display/getAside'])?'sidebar collapsed':'sidebar'}`">
        <div class="sidebar__menu-group">
            <ul class="sidebar_nav">
                <li class="menu-title">
                    <span>Main menu</span>
                </li>
                <!-- welcome -->
          
                    <!-- <li v-if="1" >
                        <a @click="onClickMenu('/welcome')"  class="">
                            <span >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home nav-icon" data-v-29a55538=""><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                            </span>
                            <span class="menu-text">Dashboard</span>
                        </a>
                    </li> -->
            
                <li :class="`has-child ${menus.Lottery?'open':''}`">
                    <a @click="menus.Lottery=!menus.Lottery" class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book-open nav-icon"><path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path><path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path></svg>
                        <span class="menu-text">Lottery</span>
                        <span class="toggle-icon"></span>
                    </a>
                    <ul v-if="menus.Lottery">
                        <li>
                            <a @click="$router.push({ name: 'ListDraw' })" class="" >Draw List Lottery</a>
                        </li>
                        <li>
                            <a @click="$router.push({ name: 'ListLotto' })" class="" >List Lottery</a>
                        </li>
                        
                        <li>
                            <a @click="$router.push({ name: 'ConfirmLotto' })" class="" >Confirm Lottery</a>
                        </li>
                        <li>
                            <a @click="$router.push({ name: 'VerifyLotto' })" class="" >Verify Lottery</a>
                        </li>
                        <li>
                            <a @click="$router.push({ name: 'DailyReport' })" class="" >Report</a>
                        </li>
                        
                    </ul>
                </li>
                <li :class="`has-child ${menus.Member?'open':''}`">
                    <a @click="menus.Member=!menus.Member" class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user nav-icon"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <span class="menu-text">Member</span>
                        <span class="toggle-icon"></span>
                    </a>
                    <ul v-if="menus.Member">
                        <li>
                            <a @click="$router.push({ name: 'ListUser' })" class="" >User List</a>
                        </li>
                    </ul>
                </li>
                <li :class="`has-child ${menus.Customer?'open':''}`">
                    <a @click="menus.Customer=!menus.Customer" class="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user nav-icon"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        <span class="menu-text">Customer</span>
                        <span class="toggle-icon"></span>
                    </a>
                    <ul v-if="menus.Customer">
                        <li>
                            <a  @click="$router.push({ name: 'ListOrders' })" class="" >Orders List</a>
                        </li>
                    </ul>
                </li>
                
                
                
                
                <!-- loop menu -->
                <!-- <div v-for="([icon, title, route, permission], index) in menus" :key="index" @click="onClickMenu(route)">
                    <li v-if="permission" >
                        <a   class="">
                            <span v-html="icon">
                              
                            </span>
                            <span class="menu-text">{{title}} </span>
                        </a>
                    </li>
                </div> -->
            </ul>
        </div>
    </aside>

</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  name: 'Aside',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
   data() {
    return {
      selectedMenu: 0,
      menus: {
        Lottery:false,
        Member:false,
        Customer:false
      }
    };
  },
  methods: {
    onClickMenu(e) {
      
    }
    // onClickMenu(link) {
    //   this.$router.push(link).catch(err => {});
    // }
  },
  mounted() {
    // this.selectedMenu = this.menus.findIndex(
    //   menu => menu[2] == this.$route.path
    // );
   
  },
 
  computed:{
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    }
  },
  watch: {
    // $route(to, from) {
    //   this.selectedMenu = this.menus.findIndex(menu => menu[2] == to.path);
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toggle-icon{
    color: rgba(238, 166, 58, 0.75);
}

li a{
   cursor: pointer; 
}
</style>
